import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Analytics from "./Analytics";
import { Link } from "react-router-dom";
import {
  getChannel,
  getTrackedChannel,
  startTracking,
  updateBackToHome,
} from "../../redux/channel/channelState";
import ReactCountryFlag from "react-country-flag";
import About from "./About";
import Videos from "./Videos";
import VideoDetail from "./videoDetail";
import Similar from "./Similar";
import { FaShareAlt } from "react-icons/fa";
import Predictions from "./Predictions";
import { setSelectedChannel } from "../../redux/home/homeState";
import { useNavigate } from "react-router-dom";

const ChAnalytics = ({
  channelDetail,
  recentVideo,
  videoStats,
  getChannel,
  getTrackedChannel,
  dailyData,
  monthlyData,
  tracked,
  startTracking,
  backToHome,
  updateBackToHome,
}) => {
  const { channelTitle } = useParams();
  const currentUrl = window.location.href;
  const lastSegment = currentUrl.split("/").pop();
  // const username = currentUrl.match(/@([^/]+)/)[1];
  const navigate = useNavigate();

  useEffect(() => {
    if (backToHome && !tracked) {
      navigate("/");
    }
  }, [backToHome, tracked]);

  useEffect(() => {
    const channelid = sessionStorage.getItem("channelId");
    if (channelid) {
      getTrackedChannel(channelTitle);
    }
  }, [channelTitle]);

  useEffect(() => {
    getChannel(channelTitle);
  }, [channelTitle]);

  useEffect(() => {
    updateBackToHome(false);
    getTrackedChannel();
  }, []);

  const startTrackingAction = async () => {
    startTracking();
  };

  const copyCurrentURL = () => {
    const currentUrl = window.location.href;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          const shareLink = document.getElementById("shareLink");
          if (shareLink) {
            shareLink.textContent = "Link Copied!";
            setTimeout(() => {
              shareLink.textContent = "Share";
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Error copying URL to clipboard:", error);
        });
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const tempInput = document.createElement("input");
      tempInput.value = currentUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      const shareLink = document.getElementById("shareLink");
      if (shareLink) {
        shareLink.textContent = "Link Copied!";
        setTimeout(() => {
          shareLink.textContent = "Share";
        }, 3000);
      }
    }
  };

  return (
    <div className="mb-5 mt-10 ">
      {channelDetail && channelDetail.snippet && (
        <div className="flex flex-col md:flex-row items-center shadow-xl bg-gray-500 rounded-md md:h-56 mt-5 justify-between text-white">
          <div className="flex flex-row items-center md:w-[50%] space-y-4 md:space-y-0 my-auto mx-5">
            <img
              src={`${channelDetail.snippet.thumbnails.default.url}`}
              alt={`${channelDetail.snippet.title}`}
              className="w-28 h-28 md:w-24 md:h-24 lg:w-44 lg:h-44 p-4 rounded-full mb-4 md:mb-0"
            />
            <div>
              <p className=" text-xl md:text-2xl lg:3xl font-bold">
                {channelDetail.snippet.title}
                <ReactCountryFlag
                  className="ml-4"
                  countryCode={`${channelDetail.snippet.country}`}
                  svg
                />
              </p>
              <div className="flex items-start">
                <p className=" text-lg md:text-md lg:text-md font-normal mt-2 md:mt-0 mr-3">
                  {channelDetail.snippet.customUrl}
                </p>
                <p className=" font-semibold mt-2 md:mt-0">
                  {channelDetail.statistics.videoCount} videos
                </p>
              </div>
              <div className="flex flex-row items-start  text-lg md:text-sm lg:text-lg font-light mt-2">
                <p>Subscribers</p>
                {/* Conditionally render the pipe separator for desktop devices */}
                <p className="mx-2">|</p>
                <p className="font-semibold">
                  {channelDetail.statistics.subscriberCount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              <div className="flex flex-row items-start  text-lg md:text-sm lg:text-lg  font-light mt-2">
                <p>Views</p>
                {/* Conditionally render the pipe separator for desktop devices */}
                <p className=" mx-2">|</p>
                <p className="font-semibold">
                  {channelDetail.statistics.viewCount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              {tracked && (
                <div className="flex flex-row items-center  text-lg md:text-sm lg:text-lg font-light mt-5">
                  <FaShareAlt className="mr-2" />{" "}
                  {/* Add margin-right to create space between icon and text */}
                  <p
                    id="shareLink"
                    className=" justify-center items-center cursor-pointer"
                    onClick={copyCurrentURL}
                  >
                    Share
                  </p>
                </div>
              )}
            </div>
          </div>

          {!tracked ? (
            <div className="flex items-center justify-center lg:pr-20 md:w-1/3">
              <div className="w-full bg-gray-500 rounded-lg mx-auto px-auto flex flex-col items-center justify-center">
                <p className="text-xl mt-2 lg:mt-0 lg:text-2xl font-semibold text-center px-auto  mb-4">
                  Tracking hasn't started for this channel
                </p>
                <button
                  className="text-md lg:text-lg px-3 py-2 font-semibold bg-red-600 hover:bg-gray-400  lg:py-1 lg:px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => {
                    startTrackingAction();
                  }}
                >
                  Start Tracking
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap justify-center md:justify-end space-x-4 mx-auto mt-4 md:mt-0">
              <Link
                to={`/${channelDetail.snippet.customUrl}/analytics`}
                className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${
                  lastSegment === "analytics"
                    ? "text-white bg-red-600"
                    : "text-white bg-transparent"
                }`}
              >
                Analytics
              </Link>
              <Link
                to={`/${channelDetail.snippet.customUrl}/about`}
                className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${
                  lastSegment === "about"
                    ? "text-white bg-red-600"
                    : "text-white bg-transparent"
                }`}
              >
                About
              </Link>
              <Link
                to={`/${channelDetail.snippet.customUrl}/videos`}
                className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${
                  lastSegment === "videos" ||
                  lastSegment === sessionStorage.getItem("selectedVideo")
                    ? "text-white bg-red-600"
                    : "text-white bg-transparent"
                }`}
              >
                Videos
              </Link>
              <Link
                to={`/${channelDetail.snippet.customUrl}/predictions`}
                className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${
                  lastSegment === "predictions"
                    ? "text-white bg-red-600"
                    : "text-white bg-transparent"
                }`}
              >
                Predictions
              </Link>
              <Link
                to={`/${channelDetail.snippet.customUrl}/similar`}
                className={`hover:bg-red-600 hover:text-white font-bold py-2 px-4 rounded ${
                  lastSegment === "similar"
                    ? "text-white bg-red-600"
                    : "text-white bg-transparent"
                }`}
              >
                Similar Channels
              </Link>
            </div>
          )}
        </div>
      )}

      {!tracked ? null : lastSegment === "analytics" ? (
        <Analytics
          recentVideo={recentVideo}
          videoStats={videoStats}
          dailyData={dailyData}
          monthlyData={monthlyData}
          channelDetail={channelDetail}
        />
      ) : lastSegment === "about" ? (
        <About channelDetail={channelDetail} />
      ) : lastSegment === "videos" ? (
        <Videos channelDetail={channelDetail} />
      ) : lastSegment === `${sessionStorage.getItem("selectedVideo")}` ? (
        <VideoDetail channelDetail={channelDetail} />
      ) : lastSegment === `similar` ? (
        <Similar channelDetail={channelDetail} />
      ) : lastSegment === `predictions` ? (
        <Predictions
          channelDetail={channelDetail}
          channelTitle={channelTitle}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.channel,
  };
};

const mapDispatchToProps = {
  getChannel: getChannel,
  getTrackedChannel: getTrackedChannel,
  startTracking: startTracking,
  setSelectedChannel: setSelectedChannel,
  updateBackToHome: updateBackToHome,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChAnalytics);
