import { createSlice } from "@reduxjs/toolkit";

export const videoSlice = createSlice({
  name: "videos",
  initialState: {
    videos: [],
    loading: true,
    pageToken: null,
  },
  reducers: {
    getVideos: (state, action) => {},
    setVideos: (state, action) => {
      if (!action.payload || action.payload.length === 0) {
        state.videos = [];
      } else {
        state.videos.push(...action.payload);
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPageToken: (state, action) => {
      state.pageToken = action.payload;
    },
  },
});

export const { setVideos, setLoading, setPageToken, getVideos } =
  videoSlice.actions;

export default videoSlice.reducer;
