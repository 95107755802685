import { put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import { setVideos, setLoading, setPageToken } from "./videoState";
const API_KEY = "AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw";

function* fetchVideos(action) {
  try {
    yield put(setLoading(true));
    yield put(setVideos([]));
    const channelId = sessionStorage.getItem("channelId");
    const channelResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails&id=${channelId}&key=${API_KEY}`
    );

    const playlistId =
      channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

    const response = yield axios.get(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&pageToken=${
        action.payload || ""
      }&order=date&type=video&key=${API_KEY}`
    );

    const newVideos = response.data.items.map((item) => ({
      videoId: item.snippet.resourceId.videoId,
      title: item.snippet.title,
      description: item.snippet.description,
      thumbnails: item.snippet.thumbnails,
      publishedAt: item.snippet.publishedAt,
      statistics: null, // Placeholder for statistics
    }));

    const videoIds = newVideos.map((video) => video.videoId);

    const statsResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoIds.join(
        ","
      )}&key=${API_KEY}`
    );

    newVideos.forEach((video, index) => {
      video.statistics = statsResponse.data.items[index].statistics;
    });

    yield put(setVideos(newVideos));

    if (
      response.data.nextPageToken &&
      response.data.pageInfo.totalResults > newVideos.length
    ) {
      yield put(setPageToken(response.data.nextPageToken));
    } else {
      yield put(setPageToken(null));
    }
  } catch (error) {
    console.error("Error fetching videos:", error);
  } finally {
    yield put(setLoading(false));
  }
}

export default function* rootSaga() {
  yield all([takeEvery("videos/getVideos", fetchVideos)]);
}
