import { put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import {
  setVideosData,
  setVideoStats,
  setChannelEngRates,
} from "./videoDetailState";

function* fetchLatestVideoStats() {
  try {
    const channelId = sessionStorage.getItem("channelId");
    if (!channelId) {
      console.error("ChannelId not found in sessionStorage");
      return;
    }

    const channelResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelId}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
    );
    const playlistId =
      channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

    const playlistItemsResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=10&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
    );
    const videoIds = playlistItemsResponse.data.items.map(
      (item) => item.snippet.resourceId.videoId
    );

    const videoStatsPromises = videoIds.map((videoId) =>
      axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
      )
    );
    const videoStatsResponses = yield Promise.all(videoStatsPromises);

    const videosData = videoStatsResponses.map((response) => {
      const video = response.data.items[0];
      return {
        key: video.id,
        videoTitle: video.snippet.title,
        date: new Date(video.snippet.publishedAt).toLocaleDateString(),
        likes: video.statistics.likeCount,
        views: video.statistics.viewCount,
        comments: video.statistics.commentCount
          ? video.statistics.commentCount
          : null,
      };
    });

    const totalLikes = videosData.reduce(
      (acc, video) => acc + parseInt(video.likes),
      0
    );
    const totalViews = videosData.reduce(
      (acc, video) => acc + parseInt(video.views),
      0
    );
    const totalComments = videosData.reduce(
      (acc, video) => (video.comments ? acc + parseInt(video.comments) : acc),
      0
    );

    const likeEngagementRate = ((totalLikes / totalViews) * 100).toFixed(2);
    const commentEngagementRate = ((totalComments / totalViews) * 100).toFixed(
      2
    );
    yield put(
      setChannelEngRates({
        likeEngagementRate,
        commentEngagementRate,
      })
    );

    yield put(setVideosData(videosData));
  } catch (error) {
    console.error("Error fetching latest video statistics:", error);
  }
}

function* getVideoStat() {
  try {
    const videoId = sessionStorage.getItem("selectedVideo");
    const videoStat = yield axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics,contentDetails,topicDetails&id=${videoId}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
    );
    yield put(setVideoStats(videoStat.data.items[0]));
  } catch (error) {
    console.error("Error fetching video statistics:", error);
  }
}
export default function* rootSaga() {
  yield all([takeEvery("videoDetail/getVideosData", getVideoStat)]);
  yield all([takeEvery("videoDetail/getVideoStats", fetchLatestVideoStats)]);
  //   yield all([takeEvery("channel/startTracking", workStartTracking)]);
}
