import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { SlLike } from "react-icons/sl";
import { GoComment } from "react-icons/go";
import { CiCalendar } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { connect } from "react-redux";
import {
  getVideosData,
  getVideoStats,
} from "../../redux/videoDetail/videoDetailState";
import { Helmet } from "react-helmet";

const VideoDetail = ({
  getVideosData,
  getVideoStats,
  videoStats,
  data,
  lightMode,
  channelDetail,
}) => {
  const calculateComparison = (actualValue, statsValue) => {
    const difference = statsValue - actualValue;
    let percentage = ((difference / statsValue) * 100).toFixed(2);
    percentage = percentage < 0 ? -percentage : percentage;
    return percentage;
  };
  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "Video Title",
      dataIndex: "videoTitle",
      key: "videoTitle",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: (text, record) => (
        <span>
          {`${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
          <Tag
            color={
              parseInt(videoStats && videoStats.statistics.viewCount, 10) <
              parseInt(record.views, 10)
                ? "green"
                : "red"
            }
          >
            {videoStats &&
              calculateComparison(
                record.views,
                videoStats.statistics.viewCount
              )}
          </Tag>
        </span>
      ),
    },
    {
      title: "Likes",
      dataIndex: "likes",
      key: "likes",
      render: (text, record) => (
        <span>
          {`${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
          <Tag
            color={
              parseInt(videoStats && videoStats.statistics.likeCount, 10) <
              parseInt(record.likes, 10)
                ? "green"
                : "red"
            }
          >
            {videoStats &&
              calculateComparison(
                record.likes,
                videoStats.statistics.likeCount
              )}
          </Tag>
        </span>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      render: (text, record) => (
        <span>
          {text ? (
            <>
              {`${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
              <Tag
                color={
                  parseInt(
                    videoStats && videoStats.statistics.commentCount,
                    10
                  ) < parseInt(record.comments, 10)
                    ? "green"
                    : "red"
                }
              >
                {videoStats &&
                  calculateComparison(
                    record.comments,
                    videoStats.statistics.commentCount
                  )}
              </Tag>
            </>
          ) : null}
        </span>
      ),
    },
  ];

  useEffect(() => {
    getVideoStats();
  }, []);

  useEffect(() => {
    getVideosData();
  }, []);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(/,/g, "/");
  };
  const formatDuration = (duration) => {
    // Parse the duration string
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    console.log(match, duration);
    // Extract hours, minutes, and seconds
    if (match) {
      const hours = match[1] ? parseInt(match[1]) : 0;
      const minutes = match[2] ? parseInt(match[2]) : 0;
      const seconds = match[3] ? parseInt(match[3]) : 0;

      // Format the duration
      const formattedDuration = [];
      if (hours > 0) {
        formattedDuration.push(`${hours}h`);
      }
      if (minutes > 0) {
        formattedDuration.push(`${minutes}m`);
      }
      if (seconds > 0 || formattedDuration.length === 0) {
        formattedDuration.push(`${seconds}s`);
      }

      return formattedDuration.join(" ");
    }
  };
  const extractCategoryName = (url) => {
    // Extract the category name from the URL
    const parts = url.split("/");
    return parts[parts.length - 1].replace(/_/g, " ");
  };
  const calculateLikeEngagementRate = (
    likeCount,
    viewCount,
    decimalPlaces = 2
  ) => {
    if (viewCount === 0) return 0;
    const rate = (likeCount / viewCount) * 100;
    return rate.toFixed(decimalPlaces);
  };
  // Function to calculate comment engagement rate
  const calculateCommentEngagementRate = (
    commentCount,
    viewCount,
    decimalPlaces = 2
  ) => {
    if (viewCount === 0) return 0;
    const rate = (commentCount / viewCount) * 100;
    return rate.toFixed(decimalPlaces);
  };

  return (
    <div className="flex flex-col md:flex-row items-start  md:h-fit mt-5">
      {/* {channelDetail.snippet && videoStats && (
        <Helmet>
          <title>
            Video Details | {videoStats.snippet.title} by{" "}
            {channelDetail.snippet.customUrl}
          </title>
        </Helmet>
      )} */}
      {videoStats && videoStats.snippet && (
        <div className=" mr-5 w-full lg:w-1/3 h-fit ">
          <div className="bg-white bg-opacity-25  opacity-100 pb-2 shadow-xl  rounded-md">
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${sessionStorage.getItem(
                "selectedVideo"
              )}`}
              title={videoStats.snippet.title}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <img
              src={`${videoStats.snippet.thumbnails.medium.url}`}
              alt={`${videoStats.snippet.title}`}
              className="w-full h-52 md:h-auto"
            /> */}
            <div className="px-3 mb-5">
              <p className="py-1 font-semibold text-md md:text-lg">
                {videoStats && videoStats.snippet.title}
              </p>
              <div className="flex items-center text-md md:text-lg font-light">
                <p className="text-xl px-3 ">
                  <IoEyeOutline />
                </p>

                <p className="font-normal">Total Views</p>
                <span className="mx-2">|</span>
                <p className="">
                  {videoStats &&
                    videoStats.statistics.viewCount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              <div className="flex items-center text-md md:text-lg font-light">
                <p className="text-lg px-3 ">
                  <SlLike />
                </p>
                <p className="font-normal">Total Likes</p>
                <span className="mx-2">|</span>
                <p className="">
                  {videoStats &&
                    videoStats.statistics.likeCount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              <div className="flex items-center text-md md:text-lg font-light">
                <p className="text-lg px-3 ">
                  <GoComment />
                </p>
                <p className="font-normal">Comments</p>
                <span className="mx-2">|</span>
                <p className="">
                  {videoStats && videoStats.statistics.commentCount
                    ? videoStats.statistics.commentCount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "Comments Hidden"}
                </p>
              </div>
              <div className="flex items-center text-md md:text-lg font-light">
                <p className="text-lg px-3 ">
                  <CiCalendar />
                </p>
                <p className="font-normal">Published At</p>
                <span className="mx-2">|</span>
                <p className="">
                  {videoStats && formatDate(videoStats.snippet.publishedAt)}
                </p>
              </div>
              <div className="flex items-center text-md md:text-lg font-light">
                <p className="text-lg px-3 ">
                  <CiClock2 />
                </p>
                <p className="font-normal">Duration</p>
                <span className="mx-2">|</span>
                <p className="">
                  {videoStats &&
                    videoStats.contentDetails &&
                    formatDuration(videoStats.contentDetails.duration)}
                </p>
              </div>
              <div className="flex items-center mt-5 text-md md:text-lg font-light">
                <p className="font-normal">Category</p>
                <span className="mx-2">|</span>
                <p className="">
                  {videoStats &&
                    videoStats.topicDetails &&
                    videoStats.topicDetails.topicCategories.map(
                      (categoryUrl, index) => (
                        <a
                          key={index}
                          className="bg-red-600  rounded-xl px-2 py-1 mr-1 font-medium text-sm text-white"
                          href={`${categoryUrl}`}
                          target="_blank"
                        >
                          {extractCategoryName(categoryUrl)}
                        </a>
                      )
                    )}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white bg-opacity-25  opacity-100 p-3 mt-2 shadow-xl  rounded-md">
            <p className="py-1 font-semibold text-xl pb-5">Tags</p>
            {videoStats && videoStats.snippet.tags ? (
              <div>
                {videoStats.snippet.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-block bg-gray-500 rounded-full px-3 py-1 text-sm font-semibold  mr-2 mb-2 text-white"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            ) : (
              <div>
                <span className="inline-block py-1 text-sm font-semibold ">
                  This video isn't using tags.
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className=" w-full lg:w-2/3  ">
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className=" border w-full mt-1 lg:mt-0 md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl bg-white bg-opacity-25  p-4 rounded-md ">
            <div className="flex justify-between pt-3  items-center">
              <p className="text-lg font-semibold ">Like Engagement Rate</p>
              <p className="text-2xl font-bold text-green-400">
                {videoStats &&
                  videoStats.statistics &&
                  calculateLikeEngagementRate(
                    videoStats.statistics.likeCount,
                    videoStats.statistics.viewCount
                  )}{" "}
                %
              </p>
            </div>
          </div>
          <div className="border w-full mt-1 lg:mt-0 md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl bg-white bg-opacity-25  p-4 rounded-md ">
            <div className="flex justify-between pt-3 items-center">
              <p className="text-lg font-semibold ">Comment Engagement Rate</p>
              <p className="text-2xl font-bold text-green-400">
                {videoStats &&
                  videoStats.statistics &&
                  calculateCommentEngagementRate(
                    videoStats.statistics.commentCount,
                    videoStats.statistics.viewCount
                  )}{" "}
                %
              </p>
            </div>
          </div>
        </div>
        {/* compariosn table */}
        <div className=" mt-5 shadow-xl  rounded-md">
          <p className="px-3 bg-white bg-opacity-25 py-5 text-2xl font-medium">
            Comparison with 10 of most recent videos
          </p>
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            className={`${lightMode ? "" : "tableClass"}`}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.videoDetail,
    lightMode: state.app.lightMode,
  };
};

const mapDispatchToProps = {
  getVideosData: getVideosData,
  getVideoStats: getVideoStats,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);
