import { combineReducers } from "redux";
import appReducer from "./app/appState";
import channelReducer from "./channel/channelState";
import topbarReducer from "./topbar/topbarState";
import similarReducer from "./similar/similarState";
import videoReducer from "./videos/videoState";
import homeReducer from "./home/homeState";
import detailReducer from "./videoDetail/videoDetailState";
import predictionReducer from "./predictions/predictionState";

const rootReducer = combineReducers({
  app: appReducer,
  channel: channelReducer,
  topbar: topbarReducer,
  similar: similarReducer,
  videos: videoReducer,
  home: homeReducer,
  videoDetail: detailReducer,
  predictions: predictionReducer,
  // Add other reducers here
});

export default rootReducer;
