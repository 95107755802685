import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSearchResults,
  getSearchSuccess,
  searchClick,
} from "../../redux/topbar/topbarState";
import { setMode } from "../../redux/app/appState";
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { IoMoonSharp } from "react-icons/io5";
import { IoMoonOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { setSelectedChannel } from "../../redux/home/homeState";

const Topbar = ({
  getSearchResults,
  search_results,
  getSearchSuccess,
  searchClick,
  search_click,
  setMode,
  setSelectedChannel,
  lightMode,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isLightMode, setIsLightMode] = useState(true);

  useEffect(() => {
    const storedMode = sessionStorage.getItem("lightMode");
    if (storedMode === "false") {
      setMode(false);
    }
  }, []);

  const toggleMode = () => {
    setMode(!lightMode);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".search-container")) {
        getSearchSuccess([]); // Hide search results if clicked outside of the search container
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery) {
      getSearchResults(searchQuery);
    } else {
      getSearchSuccess([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if ((search_click && search_click.customUrl) || search_click.title) {
      navigate(`/${search_click.customUrl || search_click.title}/analytics`);
    }
  }, [search_click]);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setSelectedChannel([]);
    navigate("/");
  };

  return (
    <div className="navbar py-5 sm:py-10 px-5 sm:px-20 flex flex-col sm:flex-row items-center justify-between">
      {/* Logo and Hamburger Menu */}
      <div className="flex justify-between w-full">
        <div
          className="logo-text text-2xl sm:text-3xl font-bold mb-2 sm:mb-0 cursor-pointer"
          onClick={handleClick}
        >
          Tubestats
        </div>
        {/* Hamburger Menu */}
        <RxHamburgerMenu
          className="block sm:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </div>
      {/* Search Input and Dark/Light Icons */}
      <div
        className={`flex items-center sm:ml-auto ${
          menuOpen ? "block sm:hidden" : "hidden sm:block"
        }`}
      >
        {/* Search Input */}
        <div className="search-container relative">
          <input
            type="search"
            name="search"
            placeholder="Search"
            className="search-input w-full sm:w-80 text-black px-4 py-2 rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500 border"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {/* Optionally, you can add a search icon here */}
          <div className="search-results absolute text-black top-full left-0 w-full bg-white mt-2 sm:mt-0 sm:w-80 rounded-lg shadow-md overflow-hidden search-container z-10">
            {search_results.length > 0 && (
              <ul>
                {search_results.map((channel) => (
                  <li
                    key={channel.id.channelId}
                    onClick={() => searchClick(channel.snippet.channelId)}
                    className="search-result-item rounded-2xl px-4 py-2 cursor-pointer hover:text-white hover:bg-red-600 flex items-center"
                  >
                    <img
                      src={channel.snippet.thumbnails.default.url}
                      alt={channel.snippet.title}
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    <div className="text-sm">{channel.snippet.title}</div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {/* Dark/Light Icons */}
        <div className="sm:hidden justify-end px-5 border rounded-2xl ml-3 ">
          {lightMode ? (
            <IoMoonOutline
              onClick={toggleMode}
              className="text-gray-500 text-3xl"
            />
          ) : (
            <CiLight onClick={toggleMode} className="text-white text-3xl" />
          )}
        </div>
      </div>
      <div className="flex items-center hidden sm:block">
        <div
          className={`flex justify-end px-5 border rounded-2xl ml-3  ${
            lightMode ? "hover:bg-gray-100" : "hover:bg-gray-800"
          }`}
        >
          {lightMode ? (
            <IoMoonOutline
              onClick={toggleMode}
              className="text-gray-500 text-3xl hover:text-gray-700 cursor-pointer"
            />
          ) : (
            <CiLight
              onClick={toggleMode}
              className="text-white text-3xl hover:text-gray-300 cursor-pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    search_results: state.topbar.search_results,
    search_click: state.topbar.search_click,
    lightMode: state.app.lightMode,
  };
}

const mapDispatchToProps = {
  getSearchResults: getSearchResults,
  getSearchSuccess: getSearchSuccess,
  searchClick: searchClick,
  setMode: setMode,
  setSelectedChannel: setSelectedChannel,
};
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
