import { put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import {
  setSearchResults,
  setSelectedChannel,
  setSelectedVideo,
  updatePopularChannels,
} from "./homeState";

function* handleSearchSubmit(action) {
  try {
    const { searchQuery, searchType } = action.payload;
    if (searchType === "Channels") {
      const response = yield axios.get(
        "https://www.googleapis.com/youtube/v3/search",
        {
          params: {
            part: "snippet",
            q: searchQuery,
            type: "channel",
            maxResults: 5,
            key: "AIzaSyDZftdreNiPBIcJcLJSW24j_ZiQbgX2kJI",
          },
        }
      );
      yield put(setSearchResults(response.data.items));
    } else {
      const params = new URLSearchParams(new URL(searchQuery).search);
      const videoId = params.get("v");

      const response = yield axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=AIzaSyDZftdreNiPBIcJcLJSW24j_ZiQbgX2kJI`
      );
      const channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${response.data.items[0].snippet.channelId}&key=AIzaSyDZftdreNiPBIcJcLJSW24j_ZiQbgX2kJI`
      );
      sessionStorage.setItem(
        "channelId",
        response.data.items[0].snippet.channelId
      );
      sessionStorage.setItem("selectedVideo", videoId);
      const customUrl = channelResponse.data.items[0].snippet.customUrl;

      yield put(setSelectedVideo({ customUrl, videoId }));
      //   yield put(navigate(`/${customUrl}/videos/${videoId}`));
    }
  } catch (error) {
    console.error("Error searching:", error);
  }
}

function* handleSearchClick(action) {
  try {
    const response = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${action.payload}&key=AIzaSyDZftdreNiPBIcJcLJSW24j_ZiQbgX2kJI`
    );

    sessionStorage.setItem("channelId", action.payload);
    yield put(setSelectedChannel(response.data.items[0]));
    // yield put(
    //   navigate(
    //     `/${
    //       response.data.items[0].snippet.customUrl ||
    //       response.data.items[0].snippet.title
    //     }/analytics`
    //   )
    // );
  } catch (error) {
    console.error("Error fetching popular channels:", error);
    return []; // Return an empty array in case of error
  }
}

function* handlePopularChannels(action) {
  try {
    const response = yield axios.get(
      `https://tubestats.io/api/popular_channels`
    );
    const channelInfoArray = [];
    // Loop through the array of channelIds
    for (const channelId of response.data.channelIds) {
      const channelResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${channelId}&key=AIzaSyDZftdreNiPBIcJcLJSW24j_ZiQbgX2kJI`
      );
      // Extract the relevant information from the response
      const channelData = channelResponse.data.items[0].snippet;

      // Push the channel information into the array
      channelInfoArray.push({
        channelId: channelId,
        title: channelData.title,
        description: channelData.description,
        thumbnails: channelData.thumbnails,
      });
    }

    yield put(updatePopularChannels(channelInfoArray));
  } catch (error) {
    console.error("Error fetching popular channels:", error);
    return []; // Return an empty array in case of error
  }
}

export default function* rootSaga() {
  yield all([takeEvery("home/getSearchResults", handleSearchSubmit)]);
  yield all([takeEvery("home/searchClick", handleSearchClick)]);
  yield all([takeEvery("home/getPopularChannels", handlePopularChannels)]);
}
