import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    searchResults: [],
    selectedChannel: [],
    selectedVideo: [],
    popularChannels: [],
  },
  reducers: {
    getSearchResults: (state, action) => {},
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },
    searchClick: (state, action) => {},
    getPopularChannels: (state, action) => {},
    updatePopularChannels: (state, action) => {
      state.popularChannels = action.payload;
    },
  },
});

export const {
  getSearchResults,
  setSearchResults,
  setSelectedChannel,
  setSelectedVideo,
  searchClick,
  getPopularChannels,
  updatePopularChannels,
} = homeSlice.actions;
export default homeSlice.reducer;
